<template>
  <div class="orderdetails">
    <van-nav-bar title="订单详情" v-if="xcx != 1" left-arrow @click-left="onClickLeft" />
    <div class="goodsmain">
      <div class="goods_list" v-if="order.goods && order.goods.length > 0">
        <van-card
          v-for="(item, index) in order.goods"
          :key="index"
          :num="item.total ? item.total : ''"
          :price="item.realprice ? item.realprice : ''"
          :title="item.goodstitle ? item.goodstitle : ''"
          :thumb="item.goodsthumb ? item.goodsthumb : ''"
          lazy-load
          @click="goodsDetail(item.goodsid)"
        >
          <template #desc>
            <div>{{item.optionname ? item.optionname : ''}}</div>
            <div v-if="item.jifen && parseFloat(item.jifen) > 0">
              <van-tag plain type="danger" size="mini">赠送：{{item.jifen}}积分</van-tag>
            </div>
          </template>
          <template #footer>
            <div class="buyer_remark" v-if="item.message">
              <van-cell>
                <template #title>
                  买家备注：
                </template>
                <template #default>
                  {{item.message}}
                </template>
              </van-cell>
            </div>
          </template>
        </van-card>
        <van-cell class="total_price" :border="false">
          <template #title>
            <div v-if="order.enoughnumsprice && parseFloat(order.enoughnumsprice) > 0">满减：&yen;<span class="red">{{order.enoughnumsprice}}</span></div>
            <div>
              实付：
              <span class="small">&yen;</span>
              <span class="red">{{ order.price }}</span>
              ({{
                order.dispatchprice && parseFloat(order.dispatchprice) > 0
                  ? "含运费：" + order.dispatchprice + "元"
                  : "免运费"
              }})
            </div>
          </template>
        </van-cell>
      </div>
    </div>
    <div class="salseseriver" v-if="order.check_status == 2 || order.refundstate > 0">
      <van-cell v-if="order.check_status == 2">
        <template #title>
          驳回原因：
        </template>
        <template #default>
          {{order.special_remark}}
        </template>
      </van-cell>
      <van-cell v-if="order.refundid > 0">
        <template #title>
          售后状态:
        </template>
        <template #default>
          {{order.refund.refundstatus_str}}
          <div v-if="order.refund.reply && order.refund.status == -1">
            {{ "原因：" + order.refund.reply }}
          </div>
        </template>
      </van-cell>
    </div>
    
    <div class="orderinfo">
      <van-cell-group>
        <van-cell
          title="订单编号："
          v-if="order.ordersn"
          :value="order.ordersn"
        >
          <template #right-icon>
            <van-button @click="copay(order.ordersn)">
              复制
            </van-button>
          </template>
        </van-cell>
        <van-cell
          title="支付方式："
          v-if="order.ordersn"
          :value="order.paytype_str"
        />
        <van-cell
          title="物流公司："
          v-if="order.expresscompany"
          :value="order.expresscompany"
        />
        <van-cell
          title="快递单号："
          v-if="order.expresssn"
          :value="order.expresssn"
        >
          <template #right-icon>
            <van-button @click="copay(order.expresssn)">
              复制
            </van-button>
          </template>
        </van-cell>
        <van-cell
          title="下单时间："
          v-if="order.createtime && order.createtime.length > 5"
          :value="order.createtime"
        />
        <van-cell
          title="支付时间："
          v-if="order.paytime && order.paytime.length > 5"
          :value="order.paytime"
        />
        <van-cell
          title="发货时间："
          v-if="order.sendtime && order.sendtime.length > 5"
          :value="order.sendtime"
        />
        <van-cell
          title="完成时间："
          v-if="order.completetime && order.completetime.length > 5"
          :value="order.completetime"
        />
      </van-cell-group>
    </div>
    <div class="shouhou_chuli" v-if="order.is_saleservice > 0 && order.is_saleservice != 2">
      <van-cell title="店主协商" ></van-cell>
      <van-field v-model="content" type="textarea" row="" placeholder="请输入协商内容" />
      <van-cell title="上传凭证" class="upload_main">
        <div class="sh_img" v-for="(item, index) in imgs" :key="index">
          <van-image
            :src="item"
            width="60"
            height="60"
            lazy-load
            fit="cover"
            style="display: block;"
            @click="yulanImg(index, imgs)"
          ></van-image>
          <div class="del_img" @click="delImg(index)">
            <van-icon name="clear" size="18px" />
          </div>
        </div>
        <van-uploader :after-read="shangchuan">
          <div class="upload_tishi">
            <van-icon name="plus" size="14px" style="margin-top: 10px;" />
            <div>上传凭证</div>
          </div>
        </van-uploader>
      </van-cell>
      <div class="btn_shouhou">
        <van-button size="small" round @click="submitShouHou(1)">提交协商结果</van-button>
        <van-button size="small" round @click="submitShouHou(2)">完结售后</van-button>
      </div>
    </div>
    <div class="postage" v-if="order.service &&  order.service.length > 0">
      <van-cell title="售后进度" ></van-cell>
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(item, index) in order.service" :key="index">
          <h3 v-if="item.content">
            {{ item.type_str + item.content }}
            <div v-if="(item.sh_img && item.sh_img.length > 0) || (item.video && item.video.length > 10)">
              <video
                v-if="item.video && item.video.length > 10"
                :src="item.video"
                height="60"
                width="60"
                webkit-playsinline="true"
                x5-video-player-type="h5"
                x5-video-orientation="portraint"
                controls
              ></video>
              <van-image v-for="(v, k) in item.sh_img" :key="k" :src="v" width="50" height="50" style="margin-right: 5px" @click="showimg(index, k)" lazy-load fit="cover"></van-image>
            </div>
            
          </h3>
          <p v-if="item.add_time_str">{{ item.add_time_str }}</p>
        </van-step>
      </van-steps>
    </div>
    <div class="postage" v-if="order.postage && order.postage.expList && order.postage.expList.length > 0">
      <van-cell title="物流进度" ></van-cell>
      <van-steps direction="vertical" :active="0">
        <van-step v-for="(item, index) in order.postage.expList" :key="index">
          <h3 v-if="item.status">{{ item.status }}</h3>
          <p v-if="item.time">{{ item.time }}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
  name: "OrderDetails",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      orderid: "", // 订单id
      orderstatus: "", //  订单状态
      order: {},
      userinfo: {},
      xcx: 0,
      content: '', // 处理售后内容
      imgs: [],
    };
  },
  mounted() {
    this.orderid = this.$route.query.id;
    this.xcx = this.$route.query.xcx ? parseInt(this.$route.query.xcx) : 0;
    this.init();
  },
  methods: {
    init() {
      console.log("订单详情");
      this.getorderinfo();
    },
    // 获取订单信息
    getorderinfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "shopsmobile/order/detail",
          _that.$qs.stringify({
            id: _that.orderid
          })
        )
        .then(response => {
          if (response && response.data && response.data.code == 100000) {
            _that.order = response.data.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 复制信息
    copay(str) {
      let _that = null;
      _that = this;
      _that.$copyText(str)
      .then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      )
    },

    // 跳转到产品详情页
    goodsDetail(id) {
      let mid = 0;
      let memberid = 0;
      mid = localStorage.getItem('SHOP_MID') ? localStorage.getItem('SHOP_MID') : 0;
      memberid = localStorage.getItem('SHOP_MUID') ? localStorage.getItem('SHOP_MUID') : 0;
      if (this.xcx == 1) {
        window.wx.miniProgram.navigateTo({
          url: "/pages/goods/details?id="+id+'&shareid='+memberid+'&merchid='+mid
        })
      }else {
        window.location.href = "https://dk.midbest.cn/details?id="+id+"&shareid="+memberid+"&merchid="+mid+"&_t=3";
      }
    },

    // 七鱼客服
    qiyukefu() {
      let _that = null;
      _that = this;
      if (window.qiyuSdkReady && (_that.merchid == 0 || this.showkefu == 1)) {
        let uid = 0;
        let name = "未登录—游客";
        let agentCode = "";
        let level = 1;
        let avatar = "";
        let levelname = "普通会员";
        let mobile = "";
        if (_that.userinfo && _that.userinfo.id > 0) {
          console.log(_that.userinfo);
          if (_that.userinfo.level == '6') {
            level = 2;
          }else if (_that.userinfo.level == '1') {
            level = 3;
          }else if (_that.userinfo.level == '14') {
            level = 4;
          }else if (_that.userinfo.level == '5') {
            level = 5;
          }
          uid = _that.userinfo.id;
          name = _that.userinfo.nickname;
          agentCode = _that.userinfo.agent_code;
          avatar = _that.userinfo.avatar;
          if (_that.userinfo.levelname) {
            levelname = _that.userinfo.levelname;
          }
          mobile = _that.userinfo.mobile;
        }else{
          _that.$toast("请先登录");
          return false;
        }

        
        window.ysf('config', {
          uid: uid,
          name: name,
          level : level, // vip级别 5囤货， 14 CEO，1 顶级，6 特级
          data: JSON.stringify([
            {"index":0, "key":"avatar","label":"头像","value":avatar},
            {"index":1, "key":"agent_code", "label":"邀请码", "value":agentCode},
            {"index":2, "key":"level", "label":"等级", "value":levelname},
            {"index":3, "key":"mobile", "label":"手机号", "value":mobile},
            {"index":4, "key":"shopid", "label":"商户id", "value":_that.merchid},
            {"index":5, "key":"shopname", "label":"商户名称", "value":localStorage.getItem("DK_SHOPNAME")},
            {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid},
          ]),
          success: function(){     // 成功回调
            window.ysf('product', {
              // sendByUser:1,
              show : 0, // 1为打开， 其他参数为隐藏（包括非零元素）
              title : _that.order.goods[0].goodstitle,
              desc : "￥" + _that.order.totalprice,
              picture : _that.order.goods[0].thumb,
              note : _that.order.ordersn,
              url : "",
              success: function(){
                window.ysf("open");
              },
              error: function() {
                console.log("sss");
              }
            })
          },
          error: function(){       // 错误回调
            // handle error
          }
        })
        
      }else{
        this.$toast("页面还未加载完，稍等一下");
      }
    },

    // 预览图片
    showimg(i, k) {
      let imgs = [];
      imgs = this.order.service[i].sh_img;
      if (imgs.length > 0) {
        ImagePreview({
          images: imgs,
          startPosition: k,
        });
      }
    },
    // 预览上传的图片
    yulanImg(i, arr) {
      ImagePreview({
        images: arr,
        startPosition: i,
      });
    },

    // 删除售后图片
    delImg(i) {
      console.log(i, "ddd");
      this.imgs.splice(i, 1);
    },
    shangchuan(file) {
      let that = null;
      that = this;
      const fordata = new FormData();
      fordata.append('file',file.file);
      fordata.append('dir','image/shouhou/');
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.imgs.push(response.data.img_url);
          Toast("上传完成")
        }else {
          Toast("上传失败");
        }
      }).catch( error =>{
        console.log(error);
      })
    },

    // 处理售后
    submitShouHou(type) {
      let that = null;
      that = this;
      let str = "";
      str = "确定要完结售后？";
      if (type == 1) {
        str = "确定要提交协商结果？";
      }

      Dialog.confirm({
        title: '处理售后',
        message: str,
      })
      .then(() => {
        // on confirm
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true, // 是否禁止背景点击
          message: '处理中...',
          overlay: true, // 是否显示背景遮罩层
        });
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/order/shouhou_chuli",
          that.$qs.stringify({
            orderid: that.orderid,
            type: type, // 1处理售后，2完结售后
            content: that.content,
            imgs: that.imgs
          })
        )
        .then(res => {
          console.log(res);
          Toast.clear();
          Toast(res.data.msg ? res.data.msg : '处理失败[no_msg]');
          if (res.data.code == 100000) {
            that.getorderinfo();
          }
        })
        .cathc(err => {
          console.log(err);
        })
      })
      .catch(() => {
        // on cancel
      });

    }
  }
};
</script>
<style lang="less">
.orderdetails {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #ededed;
  padding-bottom: 60px;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
  }
  // 地址
  .address {
    text-align: left;
    overflow: hidden;
    .van-cell {
      .van-cell__title {
        flex: 1;
      }
      .van-icon {
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }
  // 产品
  .goodsmain {
    margin-top: 10px;
    text-align: left;
    overflow: hidden;
    background-color: #fff;
    padding-top: 8px;
    .van-card:not(:first-child){
      margin-top: 0;
    }
    .van-card__tag {
      top: inherit;
      left: inherit;
      bottom: 0;
      right: 0;
      background-color: #fff;
      color: #808080;
      font-size: 14px;
      line-height: 16px;
      padding: 0 2px;
      opacity: 0.5;
    }
    // 退款按钮
    .siglegoodsBtn{
      padding: 5px 0;
      text-align: right;
      .van-button {
        margin-left: 10px;
        height: 26px;
      }
    }
    // 买家备注
    .buyer_remark{
      .van-cell{
        padding: 10px 0 0;
        line-height: 20px;
        .van-cell__value{
          flex: 1;
          text-align: left;
        }
      }
    }
    .order_btn {
      text-align: right;
      .van-cell__title {
        flex: 1;
      }
      .van-button {
        margin-left: 10px;
      }
    }
    .total_price {
      text-align: right;
      .van-cell__title {
        flex: 1;
      }
    }
    .van-card {
      background-color: #fff;
      .van-card__thumb img {
        border-radius: 4px;
      }
    }
    .red {
      color: #ff3b30;
    }
  }
  // 售后/驳回原因
  .salseseriver{
    overflow: hidden;
    margin-top: 10px;
    background-color: #fff;
    .van-cell{
      padding: 10px 16px;
      .van-cell__title {
        width: 80px;
        text-align: left;
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        color: #ff0000;
      }
    }
  }
  // 订单
  .orderinfo {
    overflow: hidden;
    margin-top: 10px;
    background-color: #fff;
    .van-cell-group {
      .van-cell {
        .van-cell__title {
          width: 80px;
          text-align: left;
        }
        .van-cell__value {
          flex: 1;
          text-align: left;
          margin-right: 5px;
        }
        .van-button {
          padding: 0 10px;
          font-size: 12px;
          height: 24px;
          line-height: 22px;
        }
      }
    }
  }
  // 物流信息
  .postage {
    margin-top: 10px;
    background-color: #fff;
    overflow: hidden;
    text-align: left;
    padding-bottom: 10px;
    .van-step__title {
      h3,
      p {
        margin: 0;
        line-height: 24px;
        font-size: 14px;
      }
    }
  }

  .shouhou_chuli {
    margin: 10px 0;
    overflow: hidden;
    background-color: #fff;
    .van-cell__value {
      flex: 1;
    }
    .upload_main {
      .van-cell__value {
        display: flex;
        align-items: center;
        justify-items: end;
        align-items: unset;
        justify-content: flex-end;
        flex-wrap: wrap;
      }
    }
    .upload_tishi {
      width: 60px;
      height: 60px;
      text-align: center;
      border: 1px dashed #eee;
      border-radius: 4px;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
      margin: 10px 10px 5px 0;
    }
    .sh_img {
      padding: 10px 10px 5px 0;
      position: relative;
      .del_img {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0px;
        right: 5px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    .btn_shouhou {
      padding: 10px;
      .van-button {
        padding: 0 16px;
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>